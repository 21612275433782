@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.media-field {
  width: vw_d(385);
  height: vw_d(34);
  padding: vw_d(3) vw_d(15) vw_d(3) vw_d(3);
  border-radius: vw_d(17);
  border: solid vw_d(2) #4a90e2;
  position: relative;
  margin-bottom: vw_d(51);

  @include screen('mobile') {
    width: vw(340);
    height: vw(49);
    padding: vw(7);
    border-radius: vw(25);
    border: solid vw(2) #4a90e2;
    margin-bottom: vw(49);
  }
  &.error {
    .error-msg {
      display: block;
    }
  }
  .remove-file {
    position: absolute;
    top: vw_d(1);
    left: vw_d(-3);
    z-index: 4;
    padding: vw_d(0.5) 0 0 vw_d(4);
    @include screen('mobile') {
      padding: vw(4) 0 0 vw(6);
      left: vw(-2);
      top: vw(1);
    }
    img {
      width: vw_d(28);
      height: vw_d(28);
      @include screen('mobile') {
        width: vw(36);
        height: vw(36);
      }
    }
  }
  label {
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    .wrapper {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: vw_d(7) vw_d(4);
      border-radius: vw_d(17);
      background-color: #fff;
      font-size: vw_d(16);
      font-family: 'NarkissBlock Regular';
      color: #979393;
      @include screen('mobile') {
        font-size: vw(21);
      }
      .file-name-wrapper {
        width: 80%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: vw_d(28);
        height: vw_d(28);
        position: absolute;
        left: vw_d(-2);
        @include screen('mobile') {
          width: vw(36);
          height: vw(36);
          left: vw(-3);
        }
      }
    }
    div {
      display: flex;
      span {
        font-family: 'NarkissBlock Regular';
        font-size: vw_d(14);
        font-weight: normal;
        color: #979393;
        padding-top: vw_d(4);
        @include screen('mobile') {
          font-size: vw(17);
          padding-top: vw(8);
        }
        &:not(:first-child) {
          direction: ltr;
        }
      }
    }
    .error-msg {
      position: relative;
      margin-top: vw_d(10);
      font-size: vw_d(14);
      color: red;
      display: none;

      @include screen('mobile') {
        margin-top: vw(13);
        font-size: vw(14);
      }
    }

    input {
      display: none;
    }
  }
}
