@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.container {

  margin-bottom: vw_d(27);
  @include screen('mobile') {
    margin-bottom: vw(35);
  }

  .form-input {
    width: vw_d(205);
    height: vw_d(34);
    padding: vw_d(7);
    border-radius: vw_d(16);
    border: solid vw_d(2) #4a90e2;
    font-family: 'NarkissBlock Regular';
    font-size: vw_d(16);
    font-weight: normal;
    color: #6d6262;
    margin-left: vw_d(20);
    &::placeholder {
      color: #979393;
    }
  }

  span {
    color: red;
    font-size: vw_d(14);
    margin-right: vw_d(5);

    @include screen('mobile') {
      font-size: vw(14);
      margin-right: vw(5);
    }
  }

 
}
