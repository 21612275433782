@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.button {
  position: relative;
  display: inline-block;
  width: 100%;
  height: vw_d(49);
  text-decoration: none;
  color: currentColor;
  border-radius: 999px;
  font-family: $bold-font;
  font-size: vw_d(20);
  line-height: 1.2;

  @include screen('desktop') {
    &:hover {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  @include screen('mobile') {
    height: vw(52);
    font-size: vw(21);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &.disabled {
    pointer-events: none;
    background-color: #b0c4cb !important;
  }

  &.withIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 18px;
      height: 18px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .label {
    position: relative;
    text-align: center;
  }
}
.redBtn {
  padding: vw_d(11);
  border-radius: vw_d(31);
  background-color: #ed1c24;
  font-family: $regular-font;
  font-size: vw_d(20);
  color: #ffffff;
  @include screen('mobile') {
    height: vw(52);
    padding: vw(12);
    border-radius: vw(45);
    font-size: vw(21);
  }
  &.disabled {
    pointer-events: none;
    background-color: #b0c4cb !important;
  }
}
