@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.form-input {
  width: vw_d(205);
  height: vw_d(34);
  padding: vw_d(7);
  border: solid vw_d(2) #4a90e2;
  border-radius: vw_d(16);
  font-family: 'NarkissBlock Regular';
  font-size: vw_d(16);
  font-weight: normal;
  color: #6d6262;
  margin-left: vw_d(20);
  @include screen('mobile') {
    width: vw(340);
    height: vw(48);
    padding: vw(10);
    border: solid vw(2) #4a90e2;
    border-radius: vw(24);
    margin-left: 0;
    font-size: vw(20);
  }
  &::placeholder {
    color: #979393;
  }
}
