@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.container {
  height: vw_d(219);
  width: vw_d(1211);
  margin-bottom: vw_d(50);
  border-radius: vw_d(13);
  border: solid vw_d(2) #4a90e2;
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  @include screen('mobile') {
    height: vw(683);
    width: 100%;
    border-radius: vw(24);
    border: solid vw(2) #4a90e2;
    margin-bottom: vw(50);
  }
  .words-limit {
    position: absolute;
    bottom: vw_d(3);
    right: vw_d(12);
    color: #b4a2a2;
    font-family: 'NarkissBlock Regular';
    font-size: vw_d(13);
    font-weight: normal;

    @include screen('mobile') {
      font-size: vw(20);
      bottom: vw(10);
      right: vw(18);
    }
  }
  .textarea {
    flex: 1;
    width: 100%;
    height: vw_d(219);
    margin-top: vw_d(8);
    padding: vw_d(14) vw_d(12) 0 vw_d(21);
    margin-bottom: vw_d(33);
    resize: none;
    font-family: 'NarkissBlock Regular';
    font-size: vw_d(19);
    line-height: vw_d(25);
    color: #979393;
    border: none;
    border-radius: inherit;
    outline: none;

    @include screen('mobile') {
      width: 100%;
      height: vw(683);
      padding: vw(16) vw(16) 0 vw(13);
      margin-bottom: vw(43);
      font-size: vw(20);
      line-height: vw(26);
    }

    &::placeholder {
      color: #979393;
    }
  }

  .error {
    color: red;
    font-size: vw_d(14);
    margin-right: vw_d(5);
    position: absolute;
    top: calc(100% + #{vw_d(5)});
    right: 0;

    @include screen('mobile') {
      font-size: vw(14);
      margin-right: vw(5);
      top: calc(100% + #{vw(5)});
    }
  }
}
