@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: vw_d(20);
  @include screen('mobile') {
    padding: vw(21) vw(16) vw(27);
  }
  .title {
    width: vw_d(1211);
    color: #2e58a6;
    font-size: vw_d(32);
    font-family: 'NarkissBlock Medium';
    text-align: center;
    span {
      color: #ed2c01;
      font-family: 'ComicSansMS';
    }
    @include screen('mobile') {
      width: 100%;
      font-size: vw(22);
    }
  }
  .form {
    width: vw_d(1211);
    margin-top: vw_d(12);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include screen('mobile') {
      width: 100%;
      margin-top: vw(15);
    }
    .label {
      font-family: 'NarkissBlock Medium';
      font-size: vw_d(23);
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: normal;
      color: #2e58a6;
      margin: 0 0 vw_d(10);
      @include screen('mobile') {
        font-size: vw(22);
        margin: 0 0 vw(19);
      }
    }
    .add-field-btn {
      width: vw_d(205);
      height: vw_d(34);
      padding: vw_d(7) 0 vw_d(6);
      margin-bottom: vw_d(96);
      border-radius: vw_d(17);
      background-color: #66b72d;
      font-family: 'NarkissBlock Regular';
      font-size: vw_d(16);
      font-weight: 600;
      color: #fff;
      text-align: center;
      @include screen('mobile') {
        width: vw(340);
        height: vw(49);
        padding: vw(11.9) 0 vw(8.1);
        margin-bottom: vw(52);
        border-radius: vw(25);
        font-size: vw(22);
      }
    }
    .input-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      @include screen('mobile') {
        display: flex;
        flex-direction: column;
      }
      .field-container {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-bottom: vw_d(45);
        @include screen('mobile') {
          flex-direction: column;
        }
        @include screen('mobile') {
          &:not(:first-child) {
            margin-top: vw(45);
          }
        }
      }
      .input-col {
        display: flex;
        flex-direction: column;

        input {
          margin-top: vw_d(10);
          &:first-child {
            margin-top: 0;
          }
          @include screen('mobile') {
            margin-top: vw(46);
          }
        }
      }
      .row {
        display: flex;
        position: absolute;
        left: vw_d(-70);
        top: 0px;
        &.single {
          left: vw_d(-30);
        }
        @include screen('mobile') {
          position: static;
          width: 100%;
          justify-content: flex-end;
        }
        img {
          height: vw_d(34);
          width: vw_d(34);
          margin-left: vw_d(7);
          cursor: pointer;
          @include screen('mobile') {
            height: vw(49);
            width: vw(49);
            margin-left: 0;
            margin: vw(29) vw(11) vw(44) 0;
          }
        }
      }
      .input-large {
        width: vw_d(385);
        padding: vw_d(6);
        border-radius: vw_d(17);
        margin: vw_d(46) 0 vw_d(3) vw_d(26);
        font-size: vw_d(16);

        @include screen('mobile') {
          width: vw(340);
          height: vw(48);
          padding: vw(10);
          border: solid vw(2) #4a90e2;
          border-radius: vw(24);
          margin-bottom: vw(3);
          font-size: vw(20);
          &:last-child {
            margin-bottom: vw(49);
          }
        }
      }
      .input-large-single {
        width: vw_d(385);
        padding: vw_d(6);
        border-radius: vw_d(17);
        margin-bottom: vw_d(43);
        @include screen('mobile') {
          width: vw(340);
          height: vw(48);
          // padding: vw(10) vw(17) vw(12) vw(241);
          padding-right: vw(10);
          padding-left: vw(10);
          border: solid vw(2) #4a90e2;
          border-radius: vw(24);
          margin-left: 0;
          font-size: vw(20);
          margin-bottom: vw(28);
          line-height: vw(22);
        }
      }
    }
    .bottom-description-wrapper {
      display: flex;
      width: vw_d(1200);
      // font-family: 'OpenSans';
      font-size: vw_d(14);
      font-weight: normal;
      line-height: 1.38;
      color: #7a7575;
      @include screen('mobile') {
        width: vw(335);
        font-size: vw(16);
      }
      .star {
        font-family: 'NarkissBlock Regular';
        font-size: vw_d(17);
        margin-left: vw_d(3);
        @include screen('mobile') {
          font-size: vw(30);
          margin: vw(-4) 0 0 vw(3);
        }
      }
      .description-content-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
    .button-wrapper {
      width: vw_d(1211);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: vw_d(65) 0 vw_d(73);
      @include screen('mobile') {
        width: 100%;
        flex-direction: column;
        margin: vw(19) 0 0;
      }
      .submit-button {
        width: vw_d(273);
        height: vw_d(55);
        padding: vw_d(7.4) vw_d(109) vw_d(7.6) vw_d(110);
        border-radius: vw_d(28);
        background-color: #0064b3;
        font-family: 'NarkissBlock Medium';
        font-size: vw_d(30);
        font-weight: 500;
        @include screen('mobile') {
          width: 100%;
          height: vw(55);
          border-radius: vw(28);
          font-size: vw(30);
          margin-top: vw(48);
        }
      }
      .checkbox-wrapper {
        position: relative;
        height: 100%;
        width: 70%;
        display: flex;
        align-items: center;
        @include screen('mobile') {
          align-items: flex-start;
          width: vw(335);
        }
        input {
          cursor: pointer;
          width: vw_d(13);
          height: vw_d(13);

          @include screen('mobile') {
            margin-left: vw(7);
            width: vw(23);
            height: vw(23);
          }
        }
        .confirmation {
          // font-family: 'OpenSans';
          font-size: vw_d(14);
          font-weight: normal;
          color: #7a7575;
          @include screen('mobile') {
            font-size: vw(16);
          }
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.errors {
  color: red;
  font-size: vw_d(14);
  line-height: vw_d(22);
  height: vw_d(22);

  @include screen('mobile') {
    font-size: vw(14);
    margin-top: vw(6);
    margin-right: vw(6);
  }
}

.confirm {
  position: absolute;
  bottom: vw_d(-20);

  @include screen('mobile') {
    bottom: vw(-20);
  }
}
