/* stylelint-disable no-descending-specificity */
@import 'src/routes/client/styles/index.scss';
@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.71;
    background-color: #0064b3;
    z-index: 1;
  }

  .content {
    position: relative;
    width: vw_d(566);
    height: vw_d(262);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-family: 'NarkissBlock Regular';
    font-size: vw_d(42);
    font-weight: 500;

    @include screen('mobile') {
      width: vw(341);
      height: vw(157);
      font-size: vw(25);
    }

    .close-icon {
      position: absolute;
      fill: #e31b23;
      cursor: pointer;
      top: vw_d(2);
      left: vw_d(2);

      @include screen('mobile') {
        top: vw(2);
        left: vw(2);
      }

      svg {
        width: vw_d(35);
        height: vw_d(35);

        @include screen('mobile') {
          width: vw(23.4);
          height: vw(23.4);
        }
      }
    }
  }
}
